import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'


const Header = styled.header`
  background: ${props => props.theme.colors.base};
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 990px) {
      flex-direction: column;
      ul li a {
        font-size: .8em;
      }
      ul li {
        display: block;
      }
  }  

  @media all and (min-device-width : 320px) {
    ul {
    }

  }  


  li {
    display: inline-block;
    margin-left: 1em;
    margin-top: 1.5em;
    &:first-child {
      position: relative;
  

    }
    &:last-child a {
      background-color: red;
      padding: 1em;
      color: white;
    }
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color: white;
    }
  }

  


`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  return (
    <Header>
      <Nav>
      <Link to="/" activeStyle={activeLinkStyle}>
          <img 
            src="https://images.ctfassets.net/2hx50hxcekhc/1WOyYb9P0lVjXAJqYzRA8W/1747db81cb1aa10c8ed9efd06aecdf44/warren-county-ky-fire.png"
            style={{ width: '100px'}}  
          />
          </Link>          
        <ul>
          <li>
            <Link to="/why-volunteer/" activeStyle={activeLinkStyle}>
              WHY VOLUNTEER
            </Link>
          </li>
          <li>
            <Link to="/how-to-join/" activeStyle={activeLinkStyle}>
              HOW TO JOIN
            </Link>
          </li>                  
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
