import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 1em 1em;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.base};
    }
  }
`

const Footer = () => (
  
  <Wrapper>
    <List>    
      <Item>
          <a href="/why-volunteer/">WHY VOLUNTEER</a>

      </Item>  
      <Item>
          <a href="/how-to-join/">HOW TO JOIN</a>
      </Item>                  

    </List>
    <div className="row">
      <div className="column footerContent">
        <p>Warren County is located in south central Kentucky and is home to the communities of Bowling Green, Smiths Grove, Woodburn, Rockfield, Rich Pond, Plano, Alvaton, and Richardsville.</p>
        <p>This site is maintained by Warren County Fire Chief's Association.</p>
      </div>
    </div>
  </Wrapper>
)

export default Footer
