import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* stylelint-disable-next-line */
    font: inherit;
    vertical-align: baseline;
  }

  /* Added to Fix Footer to bottom of viewport */
  html, body {
    height: 100%;
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background: white;
    line-height: 1;
    font-size: 1.4em;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  button,
  input {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:focus {
      outline: none;
    }
    &:invalid {
      box-shadow: none;
    }
  }

  .homeH1 {
    /* text-align: center;*/
    font-size: 1.2em;
    color: #0f436b;
    font-weight: 700;
    text-transform: uppercase;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 0 300px;
  }

  .topMargin {
    margin-top: 1em;
  }

  .topMargin li {
    padding-top: .5em;
  }

  .tripleTopMargin {
    margin-top: 2em;
  }

  .backgroundGray {
    background-color: #f4f4f4;
  }

  .secondHeroContent {
    max-width: 300px;
    text-align: left;
  }

  strong {
    font-weight: bold;
  }

  .bottomMargin {
    margin-bottom: 1em;
  }

  .department {
    padding: 1em;
    margin: 1em;
    max-width: 260px;
    background-color: #e4e4e4;
    text-align: center;
    border: 1px solid #0f436b;
  }

  .department a:visited {
    color: #121212;
  }

  .department:hover {
    background-color: #f4f4f4;
  }

  .departmentList {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: #121212;
  }

  @media screen and (max-width: 600px) {
    .department {
      padding: 1em;
      margin: 1em;
      max-width: 320px;
      background-color: #e4e4e4;
      text-align: center;
    }

    .departmentList {
      justify-content: center;
    }
  }

  .footerContent {
    text-align: center;
    margin-bottom: 1em;
  }

  .footerContent p {
    padding-top: .5em;
  }

`
export default GlobalStyle
